<template>
    <v-container>
    </v-container>
</template>
<script>
export default {
    data(){
        return {}
    },
    methods:{
    },
    created(){
        var token = localStorage.getItem("token")
        this.$store.dispatch("payment/paymentCancel", localStorage.getItem("token")).then((response) => {
            console.log(response)
            this.$router.replace({name: "PaymentStatus", params: {payment_token: token}})
        })
    }
}
</script>
<style scoped>
.border-bottom{
    border-bottom: 1px solid #d3caca
}
.white-bg {
  background: white
}
</style>